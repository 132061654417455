<template>
  <div>
    <nav
      class="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div class="navbar-brand">
        <router-link class="navbar-item brand" to="/">
          <img src="@/assets/logomark.png" width="45" />
        </router-link>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="mobileNav"
          @click="toggleNav"
          :class="{ 'is-active': mobileNavActive }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="mobileNav"
        class="navbar-menu"
        :class="{ 'is-active': mobileNavActive }"
      >
        <div class="navbar-end">
          <router-link to="/" class="navbar-item">Home</router-link>
          <router-link to="/about" class="navbar-item">About</router-link>
          <router-link to="/inventory" class="navbar-item">
            Inventory
          </router-link>
          <router-link to="/contact" class="navbar-item">Contact</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  data() {
    return {
      mobileNavActive: false,
    };
  },
  methods: {
    toggleNav() {
      this.mobileNavActive = !this.mobileNavActive;
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar-item {
  color: #d8af64 !important;
  font-family: "Lato";
  font-weight: 400;
  letter-spacing: 0.1rem;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
}
.navbar-menu.is-active {
  background: #01000c;
  border-bottom: 5px solid #d8af64;
  .navbar-item {
    &:hover {
      background: black;
    }
  }
}
</style>
