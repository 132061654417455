<template>
  <main id="app">
    <GlobalNavbar />
    <transition name="fade">
      <router-view />
    </transition>
    <GlobalFooter businessName="Jones-Holt Commercial" />
  </main>
</template>
<script>
import GlobalFooter from "@/components/global/GlobalFooter";
import GlobalNavbar from "@/components/global/GlobalNavbar";
export default {
  components: { GlobalFooter, GlobalNavbar },
};
</script>
<style lang="scss">
$primary: #0b0f1f;
$secondary: #ff4605;
#app {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #1a1f36;
}
.va {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
hr.accent {
  width: 50px;
  height: 3px;
  background-color: $primary;
}
a {
  color: #d8af64 !important;
}
// Import Bulma's core
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);

$secondary-light: findLightColor($secondary);
$secondary-dark: findDarkColor($secondary);
$secondary-invert: findColorInvert($secondary);
$radius: 0px;
$input-background-color: hsl(39, 40%, 32%);
$input-border-color: hsl(40, 49%, 15%);
$input-color: ghostwhite;
$input-icon-color: ghostwhite;
$input-arrow: ghostwhite;
// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;
// Component Overrides
// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
  (
    "white": (
      $white,
      $black,
    ),
    "black": (
      $black,
      $white,
    ),
    "light": (
      $light,
      $light-invert,
    ),
    "dark": (
      $dark,
      $dark-invert,
    ),
    "primary": (
      $primary,
      $primary-invert,
      $primary-light,
      $primary-dark,
    ),
    "secondary": (
      $secondary,
      $secondary-invert,
      $secondary-light,
      $secondary-dark,
    ),
    "link": (
      $link,
      $link-invert,
      $link-light,
      $link-dark,
    ),
    "info": (
      $info,
      $info-invert,
      $info-light,
      $info-dark,
    ),
    "success": (
      $success,
      $success-invert,
      $success-light,
      $success-dark,
    ),
    "warning": (
      $warning,
      $warning-invert,
      $warning-light,
      $warning-dark,
    ),
    "danger": (
      $danger,
      $danger-invert,
      $danger-light,
      $danger-dark,
    ),
  ),
  $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

.fade-enter-active,
.fade-leave-active {
  transition-property: opacity;
  transition-duration: 0.25s;
}

.fade-enter-active {
  transition-delay: 0.25s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
