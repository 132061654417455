import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./utils/link-resolver";
import Buefy from "buefy";

Vue.use(Buefy);
const accessToken = "";
const endpoint = "https://mtw-hillview.cdn.prismic.io/api/v2";

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
