<template>
  <main>
    <div class="container home va">
      <div>
        <div class="columns">
          <div class="column is-7">
            <div class="left">
              <h1 class="title is-1 brand">Jones-Holt Commercial</h1>
              <img src="@/assets/underline.png" alt="" />
              <br /><br />
              <p>
                Jones-Holt Commercial offers a full range of commercial
                brokerage services performed by veteran market segment
                specialists.
              </p>
              <br />
              <p class="text">
                We strive to give the client the best service available in our
                market and to accomplish the goals of the assignment in the
                shortest possible time. In each assignment we take the attitude
                of success, and we begin our work by listening to the client to
                understand his or her needs. Our knowledge and experience assure
                the client of the most advantageous arrangements for the sale or
                lease of his or her commercial property.
              </p>
            </div>
          </div>
          <div class="column is-5 va">
            <div class="square-nav">
              <router-link
                v-for="(item, index) in squareNav"
                :key="index"
                :to="item.route"
                tag="div"
                class="flex-item"
              >
                <div class="flex-item-content">
                  <div class="flex-item-content--icon">
                    <i class="fa-thin fa-2x" :class="item.icon"></i>
                  </div>
                  <div class="flex-item-content--label">
                    <em>{{ item.label }}</em>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Home",
  data: () => {
    return {
      squareNav: [
        {
          icon: "fa-home",
          label: "Home",
          route: "/",
        },
        {
          icon: "fa-users",
          label: "About Us",
          route: "/about",
        },
        {
          icon: "fa-city",
          label: "Inventory",
          route: "/inventory",
        },
        {
          icon: "fa-comments-alt",
          label: "Contact",
          route: "/contact",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  background: radial-gradient(#0a0a0eb7, rgb(0, 0, 12)),
    url("../assets/hero-2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.home {
  min-height: 100vh;
  padding: 1rem;
}
.home-content {
  display: flex;
}
.left {
  .title {
    color: #bf9c5a;
    font-family: "Playfair Display";
    letter-spacing: 0.04rem;
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
  p {
    font-family: "Lato", sans-serif;
    color: #f0f0f0;
    font-size: 1.5rem;
    letter-spacing: 0.05rem;
    font-weight: 300;
    line-height: 1.4;
    text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.5);
  }
}
.square-nav {
  flex-direction: row;
  display: -webkit-flex;
  display: inline-flex;
  width: 100%;
  align-content: center;
  flex-flow: row wrap;
}
.flex-item {
  height: 175px;
  width: 175px;
  margin: 10px;
  order: 1;
  background-color: #bf9c5a73;
  border: 1px solid #bf9c5a;
  text-align: center;
  transition: all 125ms ease-out;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #9c833e7c;
  }
  .flex-item-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: rgb(255, 252, 238);
    .flex-item-content--icon {
      font-size: 1.4rem;
      color: #eebf67;
    }
    .flex-item-content--label {
      font-size: 1.2rem;
    }
  }
}
.va {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .flex-item {
    width: calc(100vw - 4rem);
  }
  .brand {
    margin: 6rem auto !important;
    text-align: center;
  }
  p {
    font-weight: 600;
  }
  .text {
    font-size: 1.25rem !important;
    line-height: 1.4rem;
  }
}
</style>
