<template>
  <footer>
    <div class="container is-fluid">
      <div class="level">
        <div class="level-left">
          <img
            src="@/assets/logomark.png"
            width="50"
            style="margin-right: 1rem"
          />
          &copy; {{ year }} {{ businessName }}
        </div>
        <div class="level-right">
          <a
            href="https://prismic.io/dashboard/login"
            class="admin"
            target="_blank"
            rel="noopener"
          >
            <i class="fas fa-user-shield fa-sm"></i>
          </a>
          <a href="https://mitechworks.com" class="built-by">
            <i class="fas fa-code fa-sm" style="margin-right: 0.5rem"></i>by MTW
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    businessName: String,
  },
  data: () => {
    return {};
  },
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  background: linear-gradient(to right, #000111 0%, #00011159 100%);
  border-top: 2px solid #bf9c5a;
  color: #bf9c5a;
  padding: 3rem 0;
  font-family: "Lato", sans-serif;
  .built-by,
  .admin {
    font-size: 0.8rem;
    opacity: 1;
  }
  .admin {
    margin-right: 1rem;
  }
}
a {
  color: #dfb76c;
  &:hover {
    color: lighten($color: #bf9c5a, $amount: 5);
    opacity: 1;
  }
}
</style>
